import guards from './guards';

export default {
    path        : '/',
    name        : 'AuthLayout',
    component   : () => import('../layouts/BlankLayout'),
    beforeEnter : guards.statusGuard,

    children : [
        {
            path      : '/login/',
            name      : 'LoginPage',
            component : () => import('../views/authentication/login/Login')

        },
        {
            path      : '/otp-verify/:user_id/',
            name      : 'VerifyOtp',
            component : () => import('../views/authentication/login/OtpVerify')

        }
    ]
};
